<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card">
    <!--begin::Header-->
    <div class="card-header border-0  d-flex justify-content-between">
      <div
        class="font-weight-bolder text-primary text-uppercase font-size-h2"
      >
        Reports
      </div>
      <div class="d-flex">
          <input v-model="startDate" class="form-control mr-2" type="date"  style="width: 250px!important;"/>
         <input v-model="endDate" class="form-control mr-2" type="date" style="width: 250px!important;"/>
        <b-form-select v-model="currentSource"  size="md" class="mr-4" style="width: 150px!important;">
          <option
              v-for="(option, idx) in sources"
              :key="idx"
              :value="option.name"
              :title="option.name"
          >
        {{ option.name }}
      </option>
        </b-form-select>
        <b-form-input
          v-model="search_text"
          class="mr-2"
          style="width: 250px!important;"
          placeholder="Search..."
        ></b-form-input>
        <b-form-select v-model="currentUsers"  size="md" class="mr-2" style="width: 250px!important;" multiple>
          <option
              v-for="(option, idx) in tusers"
              :key="idx"
              :value="option.name"
              :title="option.name"
          >
        {{ option.name }}
      </option>
        </b-form-select>
        <b-button variant="primary" v-if="!loading"  pill class="btn btn-primary py-2 mt-1 mx-4" style="height: 40px" @click="loadReports">
          Report
        </b-button>
        <b-button variant="primary" v-else disabled pill class="btn btn-primary py-2 mt-1 mx-4" style="height: 40px">
          <b-spinner small type="grow" class="mr-2"></b-spinner>Loading...
        </b-button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
       <div id="chart">
        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

import {
  GET_REPORTS,
} from "../../../../core/services/store/tweet.module";

import {
  GET_TUSERS,
} from "../../../../core/services/store/tuser.module";


export default {
  name: "transactions",
  components: {
  },
  data() {
    let today = new Date();
    let yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let lastDate =  new Date(yyyy, mm, 0);
    let dd = lastDate.getDate()
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    let date = yyyy + '-' + mm + '-' + dd;
    let start_date = yyyy + '-' + mm + '-' + '01';
    return {
          startDate: start_date,
          endDate: date,
          currentUsers: [],
          tusers: [],
          currentSource: 'All',
          sources: [{name: 'All'}, {name: 'Twitter'}, { name : "Archive"}],
          search_text: '',
          series: [],
          loading: false,
          days : []
    };
  },
  computed: {
    ...mapGetters(["layoutConfig", "currentUserAccountInfo"]),
    chartOptions() {
      let options =   {
            chart: {
              type: 'bar',
              height: 750
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: this.days,
            },
            yaxis: {
              title: {
                text: 'Tweets Count'
              }
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return  val + " Tweets"
                }
              }
            }
         };
      return options;
    }
  },
  methods: {
     loadReports(){
      if(this.currentOffice !== null){
         this.series = [];
         this.loading = true;
         this.$store
          .dispatch(GET_REPORTS, {
            users: this.currentUsers,
            start: this.startDate,
            source: this.currentSource,
            search: this.search_text,
            end: this.endDate
          })
          .then((data) => {
            this.series = data.series;
            this.days = data.days;
            this.loading = false;
          })
      }
    },
    loadUsers(){
         this.$store
          .dispatch(GET_TUSERS)
          .then((data) => {
              this.currentUsers = data.map(entry => entry.name),
              this.tusers= data;
              this.loadReports();
          })
      }
  },
  beforeMount() {

  },
  created() {
    this.loadUsers();

  },
   watch: {

  },
};
</script>
<style>

</style>
